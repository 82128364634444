import React from 'react'
import { Provider } from 'react-redux'
import { createStore as reduxCreateStore } from 'redux'
import rootReducer from '.'

const createStore = () => reduxCreateStore(rootReducer)

export default ({ element }) => {
  console.log("--------------------------------------------")
  console.log(
    "%c                    Made by",
    'text-align: center; color: #006cff;font-size: 18px; font-family: "Roboto", sans-serif'
  )
  console.log(
    "%cInvisual Branding Solutions",
    'color: white ; font-size: 24px; font-family: "Roboto", sans-serif'
  )
  console.log("--------------------------------------------")


  return (
      <Provider store={createStore()}>{element}</Provider>
  )
}