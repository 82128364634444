const initialState = {
    hideLogoMenu: false,
    toggleColorMenu: false,
    bgColor: "#eceae7"
}

const TOGGLE_COLORMENU = 'TOGGLE_COLORMENU'
const TOGGLE_HIDELOGOMENU = 'TOGGLE_HIDELOGOMENU'
const TOGGLE_BGCOLOR = 'TOGGLE_BGCOLOR'


export const toggleColorMenu = toggleColorMenu => ({
    type: TOGGLE_COLORMENU, toggleColorMenu
})
export const toggleHideLogoMenu = hideLogoMenu => ({
    type: TOGGLE_HIDELOGOMENU, hideLogoMenu
})
export const toggleBgColor = bgColor => ({
    type: TOGGLE_BGCOLOR, bgColor
})


const App =  (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_COLORMENU:
            return { ...state, toggleColorMenu: action.toggleColorMenu }
        case TOGGLE_HIDELOGOMENU:
            return { ...state, hideLogoMenu: action.hideLogoMenu }
        case TOGGLE_BGCOLOR:
            return { ...state, bgColor: action.bgColor }
        default:
            return state
    }
}
export default App